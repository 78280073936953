<template>
  <div id="app">
    <!-- banner + 用户 -->
    <div class="bg_green">
      <div class="banner_all">

        <!-- banner -->
        <div class="banner" v-if="!showGFBanner()">
          <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item class="clear"><img src="../assets/images/advert.jpg" alt=""></van-swipe-item>
          </van-swipe>
        </div>
        <div class="banner" v-if="showGFBanner()">
          <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item class="clear"><img src="../assets/images/guangfa3500/banner_guangfa3500.jpg" alt="" @click="onClickBanner">
            </van-swipe-item>
          </van-swipe>
        </div>

        <van-loading v-if="loading"/>

        <!-- 用户 -->
        <div class="user shadow clear">
          <div class="user_msg_all clear" v-if="customerUser">
            <div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>
            <div class="user_msg">
              <span v-if="customerUser.businessCustomerUser">{{ customerUser.businessCustomerUser.name }}</span>
              <label
                  v-if="customerUser.businessCustomerUser">{{
                  customerUser.businessCustomerUser.business.name
                }}，{{ customerUser.businessCustomerUser.structure.name }}</label>
            </div>
            <div class="user_r">
              <router-link to="switch_user">
                <img src="../assets/images/user_change.png" alt=""> 切换用户
              </router-link>
            </div>
          </div>
          <div class="user_msg_all clear" v-if="!customerUser">
            <div class="user_avatar" :style="{backgroundImage:`url(${img2})`}"></div>
            <div class="user_msg relevance" @click="jumpSwitch">关联用户</div>
            <div class="user_r2 right_3p6vw iconfont">&#xeb06;</div>
          </div>
        </div>
      </div>
    </div>

    <div class="idx_entrance" v-if="weekMenus" style="display: flex; align-items: center;" @click="routeToWeekMenus">
      <van-icon name="bell" size="20" color="#42b983"/>

      <div
          style="font-size: 14px; line-height: 14px; margin-left: 2vw; color: #333333; overflow: hidden; text-overflow: ellipsis; width: 100%; white-space: nowrap;">
        一周菜单
      </div>

      <van-icon name="arrow" size="20" color="#999999"/>
    </div>

    <!-- 金刚区 -->
    <div class="idx_entrance" style="">
      <ul>
        <li class="now" @click="jumpAccount">
          <span><img src="../assets/images/ico_entrance4.png" alt=""></span>
          <label>充值缴费</label>
        </li>
        <!--        <li class="now" @click="routeToTuitionAndFees">-->
        <!--          <span><img src="../assets/images/ico_entrance7.png" alt=""></span>-->
        <!--          <label>学费杂费</label>-->
        <!--        </li>-->
        <li @click="jumpBundleCalendar">
          <span><img src="../assets/images/ico_entrance5.png" alt=""></span>
          <label>停/换餐</label>
        </li>
        <li>
          <router-link to="about">
            <span><img src="../assets/images/ico_entrance6.png" alt=""></span>
            <label>账户设置</label>
          </router-link>
        </li>
      </ul>
    </div>

    <!-- 餐食列表 -->
    <div class="idx_entrance">
      <div class="idx_entrance_b clear">
        <div class="idx_entrance_b_l">今日餐食（{{ getRq() }}）</div>
        <div class="idx_entrance_b_r">
          <dl class="clear">
            <dd class="now" @click="prevRq">上一天</dd>
            <dd :class="isToday" @click="nextRq">下一天</dd>
          </dl>
        </div>
      </div>
      <div class="pic_list1">
        <van-loading v-if="nutritiveLoading"/>

        <div v-for="(option,index) in nutritiveOptions" :key="index" v-if="!nutritiveLoading">
          <!-- 标题 -->
          <div class="title">
            <div class="title_l">{{ option.name }}</div>
            <div class="title_r">
                        <span class="green" @click="jumpNutrient(option)"><img src="../assets/images/ico_meals1.png"
                                                                               alt=""> 营养元素</span>
              <span @click="jumpSuyuan(option)"><img src="../assets/images/ico_meals2.png" alt=""> 溯源</span>
            </div>
          </div>

          <!-- 图片列表 -->
          <dl class="clear" @click="jumpNutrient(option)">
            <dt :style="{backgroundImage:`url(${getImage(option)})`}" style="background-size: 100% 100%;"></dt>
            <dd v-if="option.mainCourse"><span style="color:#818181;">主菜: </span>{{ option.mainCourse }}</dd>
            <dd v-if="option.sideDish"><span style="color:#818181;">配菜: </span>{{ option.sideDish }}</dd>
            <dd v-if="option.vegetables"><span style="color:#818181;">时蔬: </span>{{ option.vegetables }}</dd>
            <dd v-if="option.stapleFood"><span style="color:#818181;">主食: </span>{{ option.stapleFood }}</dd>
            <dd v-if="option.snack"><span style="color:#818181;">小食: </span>{{ option.snack }}</dd>
            <dd v-if="option.soup"><span style="color:#818181;">汤品: </span>{{ option.soup }}</dd>
            <div class="pic_list_link iconfont">&#xeb06;</div>
          </dl>
        </div>

        <van-empty v-if="(!nutritiveOptions || nutritiveOptions.length===0) && !nutritiveLoading"
                   description="暂无数据"/>
      </div>
    </div>

    <div class="height_5vw"></div>

    <div class="loading_all" v-if="all_Loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

    <van-popup v-model="showCannotRechargeDialog">
      <img src="../assets/images/recharge/dialog_background_cannot_recharge.png" alt=""
           style="height: 315px; width: 260px;">
      <img
          src="../assets/images/recharge/dialog_button_cannot_recharge.png"
          alt=""
          style="height: 25px; width: 111px; position: absolute; bottom: 20px; left: 30%"
          @click="showCannotRechargeDialog = false">
    </van-popup>

    <!-- 弹窗公告 -->
    <van-popup v-model="showGFActivityPopup">
      <img src="../assets/images/guangfa3500/dialog_guangfa3500.jpg" alt=""
           style="height: 333px; width: 250px;"
           @click="onClickBanner()">
    </van-popup>

  </div>
</template>

<style scoped>
/* 首页金刚区 */
.idx_entrance {
  width: 93vw;
  padding: 3vw;
  margin: 0 auto;
  margin-top: 2vw;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1pt 2.67pt #ededed;
}

.idx_entrance ul {
  width: 100%;
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.idx_entrance ul li {
  /*width: 25%;*/
  position: relative;
  float: left;
}

.idx_entrance ul li span {
  width: 100%;
  height: 11vw;
  border-radius: 5px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.idx_entrance ul li span img {
  width: 11vw;
}

.idx_entrance ul li label {
  width: 100%;
  display: block;
  float: right;
  font-size: 13px;
  text-align: center;
  height: unset;
  line-height: unset;
}
</style>

<script>
import Vue from 'vue'
import {ACCESS_TOKEN, ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import moment from 'moment'
import {Dialog, Icon} from 'vant';
import {GF_ACTIVITY_ADVERT, NUTRIENT_SOURCE} from "../store/mutation-types";
import {getUserIpConfig} from '@/utils/ipUtils'

Vue.use(Icon)

export default {
  data() {
    return {
      loading: false,
      all_Loading: false,
      nutritiveLoading: false,
      img: require('../assets/images/avatar.png'),
      img2: require('../assets/images/avatar_relevance.png'),
      pic1: require('../assets/images/pic1.jpg'),
      accessToken: '',
      customerUser: {},
      nutritiveOptions: [],
      rq: null,
      isToday: '',
      weekMenus: null, // 一周菜单
      showCannotRechargeDialog: false,
      showGFActivityPopup: false
    }
  },
  mounted() {
    // 防止后退
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL);
    });

    this.customerUser = Vue.ls.get(ACCESS_CustomerUser);

    if (Vue.ls.get(ACCESS_TOKEN)) {
      // 检查数据是否还存在关系
      this.loading = true;
      post('api/customer/user/v2/search', {
        page: 0,
        size: 500,
      }).then(res => {
        if (res.data.rows.length > 0) {
          if (this.customerUser) {
            let find = res.data.rows.find(item => item.id === this.customerUser.id);
            if (!find) {
              // 如果关系已解除，则取第一个当成默认
              Vue.ls.set(ACCESS_CustomerUser, res.data.rows[0], 7 * 24 * 60 * 60 * 1000);
              this.customerUser = res.data.rows[0];
            } else {
              this.customerUser = find;
              Vue.ls.set(ACCESS_CustomerUser, find, 7 * 24 * 60 * 60 * 1000);
            }
          } else {
            Vue.ls.set(ACCESS_CustomerUser, res.data.rows[0], 7 * 24 * 60 * 60 * 1000);
            this.customerUser = res.data.rows[0];
          }

          this.loadNutritive();
          this.loadWeekMenus();

        } else {
          // 未绑定过消费者的用户，跳转去绑定身份
          this.$router.push({path: '/switch'});

        }
        this.loading = false;

      }).catch(() => {
        this.loading = false;
        this.$router.push({path: '/switch'});
      });

      // 检查是否有待确认的停餐明细
      post('api/business/v2/taskReservationConfirm/user/inspect', {}).then(res => {
        this.loading = false;
        if (res.data === 'false') {
          return;
        }

        Dialog.confirm({
          title: '温馨提示',
          message: '您有停餐明细未确认，是否进行处理?',
          confirmButtonText: '前往确认',
          cancelButtonText: '下一次吧'
        })
            .then(() => {
              this.$router.push({path: '/reservationRecordConfirmList'});
            })
            .catch(() => {

            });
      }).catch(() => {
        this.loading = false;
      });
    }

    this.rq = new Date()
    this.loadAdvert();
    this.checkSyncInterest();

    this.getAddress();
  },
  methods: {
    checkSyncInterest() {
      let syncInterestTime = Vue.ls.get("syncInterestTime");
      if (syncInterestTime) {
        const now = new Date();
        // 每两天进行一次结息同步
        if ((now.getTime() - 60 * 60 * 24 * 2) > syncInterestTime) {
          this.syncInterest();
        }

      } else {
        // 同步
        this.syncInterest();
      }
    },
    // 同步结息
    syncInterest() {
      post("api/guangfa/v3/account/sync/interest", {}).then(res => {
        Vue.ls.set("syncInterestTime", (new Date()).getTime())

      }).catch(() => {

      })
    },
    jumpBundleCalendar() {
      // 汾江中学则跳到指定的链接
      if (this.customerUser && this.customerUser.businessCustomerUser && this.customerUser.businessCustomerUser.businessId === '017b972ae06b003b2c96c1f37b970f88') {
        window.location.href = 'https://jinshuju.net/f/WqzaDK';
        return;
      }

      this.$router.push({path: '/bundle_calendar'})
    },
    jumpAccount() {
      this.$router.push({path: '/customer_user_account'});
    },
    routeToTuitionAndFees() {
      this.$router.push({path: '/tuition/tuitionAndFeesList'})
    },
    getRq() {
      return moment(this.rq).format('YYYY-MM-DD')
    },
    prevRq() {
      this.rq = new Date(this.rq.setDate(this.rq.getDate() - 1));
      this.isToday = moment(this.rq).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD') ? '' : 'now'
      this.loadNutritive()
    },
    nextRq() {
      this.isToday = moment(this.rq).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD') ? '' : 'now'
      if (this.isToday) {
        this.rq = new Date(this.rq.setDate(this.rq.getDate() + 1));
        this.isToday = moment(this.rq).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD') ? '' : 'now'
        this.loadNutritive()
      }
    },
    jumpSwitch() {
      this.$router.push({path: '/switch', query: {back: true}})
    },
    loadWeekMenus() {
      if (!this.customerUser || !this.customerUser.businessCustomerUser || !this.customerUser.businessCustomerUser.business) {
        return;
      }

      let businessId = this.customerUser.businessCustomerUser.business.id
      post('api/business/v2/' + businessId + '/dietary/nutrition/read/recipes/photo', {}).then(res => {
        if (!res.data.url || res.data.url === '') {
          this.weekMenus = null;
          return;
        }

        this.weekMenus = res.data;

      }).catch(() => {
        this.weekMenus = null;
      })
    },
    loadNutritive() {
      this.nutritiveOptions = [];
      if (!this.customerUser || !this.customerUser.businessCustomerUser || !this.customerUser.businessCustomerUser.business) {
        return;
      }
      this.nutritiveLoading = true;
      let rq = this.getRq()

      let businessId = this.customerUser.businessCustomerUser.business.id

      const url = 'api/business/v2/{businessId}/dietary/nutrition/read/{day}/recipes'
          .replace('{businessId}', businessId)
          .replace('{day}', rq)

      post(url, {}, true).then(res => {
        if (res.data && res.data.length > 0) {
          this.nutritiveOptions = res.data
        }
        this.nutritiveLoading = false;
      }).catch(() => {
        this.nutritiveLoading = false
      });
    },
    routeToWeekMenus() {
      this.$router.push({path: '/indexWeekMenus', query: {data: JSON.stringify(this.weekMenus)}});
    },
    jumpNutrient(data) {
      Vue.ls.set(NUTRIENT_SOURCE, data);
      this.$router.push({path: '/nutrientSource', query: {id: data.id, rq: this.getRq()}})
    },
    jumpSuyuan(data) {
      Vue.ls.set(NUTRIENT_SOURCE, data);
      this.$router.push({path: '/nutrientSource', query: {id: data.id, rq: this.getRq(), suyuan: true}})
    },
    getImage(data) {
      if (data.thumbnailImage) {
        // return 'http://crm.fsytss.com' + data.thumbnailImage;
        return data.thumbnailImage;
      }
      return require('../assets/images/pic_default.jpg');
    },
    loadAdvert() {
      if (!this.showGFBanner()) {
        return;
      }
      let value = Vue.ls.get(GF_ACTIVITY_ADVERT);
      if (value) {
        return;
      }
      this.showGFActivityPopup = true;
      Vue.ls.set(GF_ACTIVITY_ADVERT, true);
    },
    loadBusinessPaymentInfo() {
      post("api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace('{businessId}', this.customerUser.businessId))
          .then(result => {
            // Vue.ls.set(ACCESS_PAYMENT_INFO, result.data);
          })
          .catch(error => {
            console.log(error.message);
          });
      post('api/business/v2/{businessId}/setting/search'.replace('{businessId}', this.customerUser.businessId), {
        size: 500
      })
          .then(res => {
            let showAccountBalance = res.data.rows.find(item => item.setKey === 'SHOW_ACCOUNT_BALANCE'); // 仅解冻自由充值金额
            if (!showAccountBalance) {
              showAccountBalance = {setValue: '1'};// 默认为否
            }
          })
          .catch(() => {
          });
    },

    getAddress() {
      getUserIpConfig().then(res => {
      });
    },

    onClickBanner() {
      this.showGFActivityPopup = false;
      if (!this.customerUser || !this.customerUser.businessCustomerUser) {
        return;
      }
      // window.location.href = 'https://www.wjx.top/vm/Y23FSOc.aspx#';

      // this.jumpAccount();
      this.$router.push({name: 'activityGuangfa3500'});

    },
    showGFBanner() {
      // if (!this.customerUser || !this.customerUser.businessCustomerUser) {
      //   return false;
      // }
      // const businessId = this.customerUser.businessCustomerUser.businessId;
      // // 深村小学、石湾一小、三龙湾小学、紫南小学、铁军小学、黎涌小学、冼可澄纪念小学、保利天珀幼儿园、惠雅幼儿园、一天测试学校
      // // 一天膳事测试学校
      // if (
      //     businessId === '017c0b4f337b06b72c96c1f37bfa026a' ||
      //     businessId === '017c124eaa6c14f42c96c1f37c0e1ec6' ||
      //     // businessId === '017b9b99ec221ad92c96c1f37b973694' ||
      //     businessId === '017c1096128a03a82c96c1f37c0e1ec6' ||
      //     businessId === '017be2d1e83a68ca2c96c1f37bdfc369' ||
      //     businessId === '017ba96e9316061c2c96c1f37ba23995' ||
      //     businessId === '017c7275f66e01bf2c96c1f37c70498f' ||
      //     // businessId === '018266eaad4c26082c96c1f382573a7f' ||
      //     // businessId === '01821afe2ad029852c96c1f3820a96e9' ||
      //     businessId === '017b97396de9000a2c96c1f37b973694') {
      //   return true;
      // }
      return false;
    }
  }
};
</script>
